import React, {useState} from 'react';

import Special from '../Special/Special';

export default function Door(props) {

    const [turn, setTurn] = useState(0);
    const handleTurn = () => {
        if(!props.directOpen) {
            setTurn(1)
        }
    }

    return (
        <>
            <div className="day">
                {props.day !== 'leer-1' && props.day !== 'leer-2' ? props.day : ''}
            </div>

            <Special special={props.special} />

            <div className={"message" + (turn ? " turn" : "")} onClick={handleTurn}>
                <div className="question">
                    {props.message}
                </div>
                <div className="answer">
                    {props.answer}
                </div>
            </div>
        </>
    )
}