import React from 'react';

import imageCandle from '../../../Images/kerze.png';
import imageNicolaus from '../../../Images/nicolaus.png';
import imageChristmas from '../../../Images/weihnachten.png';

export default function Candle(props) {

    return (
        <div className={
            'Special'
            + (props.special === 'nicolaus' ? ' nicolaus' : '')
            + (props.special === 'christmas' ? ' christmas' : '')
            }>
            {props.special === 'advent1'
                && <>
                    <span>1</span>
                    <img src={imageCandle} alt='Advent'/>
                </>
            }
            {props.special === 'advent2'
                && <>
                    <span>2</span>
                    <img src={imageCandle} alt='Advent'/>
                </>
            }
            {props.special === 'advent3'
                && <>
                    <span>3</span>
                    <img src={imageCandle} alt='Advent'/>
                </>
            }
            {props.special === 'advent4'
                && <>
                    <span>4</span>
                    <img src={imageCandle} alt='Advent'/>
                </>
            }
            {props.special === 'nicolaus'
                && <>
                    <img src={imageNicolaus} alt='Nikolaus' /> 
                </>
            }
            {props.special === 'christmas'
                && <>
                    <img src={imageChristmas} alt='Weihnachten' /> 
                </>
            }
        </div>
    )
}