export const calendar = [
    {
        "day": "01",
        "message": "Bis zur Unendlichkeit und noch viel weiter!",
        "answer": "Toy Story"
    },
    {
        "day": "02",
        "message": "Quizfrage, Arschloch",
        "answer": "Speed"
    },
    {
        "day": "03",
        "message": "Sprich zu der Hand",
        "answer": "Terminator"
    },
    {
        "day": "04",
        "message": "Ich wusste gar nicht, dass man Scheiße so hoch stapeln kann",
        "answer": "Full Metal Jacket",
        "special": "advent2"
    },
    {
        "day": "05",
        "message": "Yippie-ya-yeah Schweinebacke",
        "answer": "Stirb langsam"
    },
    {
        "day": "06",
        "message": "Ist sie tot?",
        "answer": "Der blutige Pfad Gottes",
        "special": "nicolaus"
    },
    {
        "day": "07",
        "message": "Koks ist toter als tot. Heroin kommt zurück und zwar im ganz großen Stil",
        "answer": "Pulp Fiction"
    },
    {
        "day": "08",
        "message": "Houston, wir haben ein Problem",
        "answer": "Apollo 13"
    },
    {
        "day": "09",
        "message": "Morgen ist auch noch ein Tag",
        "answer": "Vom Winde verweht"
    },
    {
        "day": "10",
        "message": "Ich bin zu alt für diesen Scheiß",
        "answer": "Lethal Weapon"
    },
    {
        "day": "11",
        "message": "Amerikasniche Bauteile, russische Bauteile ... die kommen doch alle aus Taiwan",
        "answer": "Armageddon",
        "special": "advent3"
    },
    {
        "day": "12",
        "message": "Ich bin der König der Welt!",
        "answer": "Titanic"
    },
    {
        "day": "13",
        "message": "I'll be back",
        "answer": "Terminator"
    },
    {
        "day": "14",
        "message": "Das Leben ist wie eine Schachtel Pralinen, man weiß nie man kriegt",
        "answer": "Forrest Gump"
    },
    {
        "day": "15",
        "message": "Er hat mich angeschleimt!",
        "answer": "Ghostbusters"
    },
    {
        "day": "16",
        "message": "Straßen? Wo wir hinfahren, brauchen wir keine Straßen!",
        "answer": "Zurück in die Zukunft"
    },
    {
        "day": "17",
        "message": "Warum denn so ernst?",
        "answer": "The dark night"
    },
    {
        "day": "18",
        "message": "Nach Hause telefonieren",
        "answer": "E.T.",
        "special": "advent4"
    },
    {
        "day": "19",
        "message": "Ich hab eine Wassermelone getragen",
        "answer": "Dirty Dancing"
    },
    {
        "day": "20",
        "message": "Chantal, heul leiser",
        "answer": "Fack yu Göthe"
    },
    {
        "day": "21",
        "message": "Oh Captain, mein Captain",
        "answer": "Club der toten Dichter"
    },
    {
        "day": "22",
        "message": "Guten Morgen. Oh, und falls wir uns nicht mehr sehen, guten Tag, guten Abend und gute Nacht",
        "answer": "Die Truman Show"
    },
    {
        "day": "23",
        "message": "Hasta la vista, Baby!",
        "answer": "Terminator 2"
    },
    {
        "day": "24",
        "message": "Möge die Macht mit dir sein",
        "answer": "Star Wars",
        "special": "christmas"
    },
    {
        "day": "leer-1"
    },
    {
        "day": "leer-2"
    }
]
