import React from 'react';
import './App.scss';
import Calendar from './Components/Organism/Calendar/Calendar';
import backgroundImage from './Images/background.webp';

function App() {

    let background = {
        backgroundImage: 'url('+backgroundImage+')'
    };

    return (
        <div className="App" style={background}>
            <Calendar />
        </div>
    );
}

export default App;
