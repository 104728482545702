import React, {useState} from 'react';
import Door from '../../Atom/Door/Door';

export default function Day(props) {

    const [isActive, setIsActive] = useState(0);

    function handleClick(event) {
        let today = new Date();
        let open = true;
        if(
            (today.getMonth() + 1) 
            && props.day > (today.getDate() < 9 ? "0"+today.getDate() : today.getDate())) {
            open = false;
        }
        if(!props.directOpen) {
            setIsActive(open)
        }
    }

    return (
        <div className={
            "day day-"+props.day 
            + (props.directOpen ? ' open' : '') 
            + (props.today ? ' currentDay' : '')
            + (isActive ? ' isActive' : '')
            } onClick={handleClick}>
            <Door 
                day={props.day}
                message={props.message}
                answer={props.answer}
                directOpen={props.directOpen}
                special={props.special}
            />
        </div>
    )
}