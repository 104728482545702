import React from 'react';
import { calendar } from '../../../calendar';
import Day from '../../Molecule/Days/Day';


export default function Calendar(props) {

    const directOpen = (day) => {
        let open = false;
        let today = new Date();
        if(
            (today.getMonth() + 1) 
            && day < (today.getDate() < 9 ? "0"+today.getDate() : today.getDate())) {
            open = true;
        }
        return open;
    }

    const isToday = (day) => {
        let today = new Date();
        let isToday = parseInt(day) === parseInt(today.getDate()) ? true : false; 
        return isToday;
    }

    return (
        <div className='calendar'>
            {calendar.map((date, key) => {
                key = (key < 10 ? "0"+key : key)
                return (
                    <Day 
                        day={date.day}
                        message={date.message}
                        answer={date.answer}
                        key={key}
                        directOpen={directOpen(date.day)}
                        today={isToday(date.day)}
                        special={date.special}
                    />
                )
            })}
        </div>
    )

}